<template>
  <div id="app">
    <Form/>
    <div class="container">
      <footer class="py-3 my-4">
        <p class="text-center text-muted">©{{currentYear}} Water Tower Dog Park</p>
      </footer>
    </div>
  </div>
  
</template>

<script>
import Form from './components/Form.vue'

export default {
  name: 'App',
  components: {
    Form
  },
  computed: {
    currentYear(){
      return new Date().getFullYear();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

@import '~bootstrap/dist/css/bootstrap.css';
@import './css/stripe.css';
@import './css/loading.css';
@import './css/loading-btn.css';
</style>
