<template>
  <div class="container-xxl pt-4 pl-4">
    <form>
    <div class="row">
      <div class="col-12 mb-3">
        <h1 class="text-center">Water Tower Dog Park</h1>
        <h3 class="text-center">Annual Membership Application</h3>
      </div>
      <div class="col-12 mb-3">
        <p class="text-center">The Water Tower Dog Park (“WTDP”) is a Missouri nonprofit corporation established to construct, maintain and operate the dog park facilities located in Compton Hill Reservoir Park. The information requested below is required for approval of use of the WTDP by you and your dog(s).</p>
      </div>

      <div class="col-12 mb-3 text-center">
        <div class="form-check form-check-inline">
          <input required type="radio" class="form-check-input" id="newApplicant" name="newApplicant" checked>
          <label class="form-check-label" for="newApplicant">New Applicant</label>
        </div>
        <div class="form-check form-check-inline">
          <input required type="radio" class="form-check-input" id="existingApplicant" name="newApplicant" value="false">
          <label class="form-check-label" for="existingApplicant"> Annual Renewal (Jan 1 - Dec 31)</label>
        </div>
      </div>
    </div>

  <div class="card mb-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5>Applicant Contact Info</h5>
      </div>
      <div class="card-body">
        <div class="row">

      <div class="col-sm-6 mb-3">
        <div class="form-floating">
          <input required type="text" class="form-control" id="firstName" placeholder="First Name" name="firstName">
          <label for="firstName">First Name</label>
        </div>
      </div>
      <div class="col-sm-6 mb-3">
        <div class="form-floating">
          <input required type="text" class="form-control" id="lastName" placeholder="Last Name" name="lastName">
          <label for="lastName">Last Name</label>
        </div>
      </div>
      <div class="col-sm-6 mb-3">
        <div class="form-floating">
          <input required type="email" class="form-control" id="emailAddress" placeholder="Email Address" name="emailAddress">
          <label for="emailAddress">Email Address</label>
        </div>
      </div>
      <div class="col-sm-6 mb-3">
        <div class="form-floating">
          <input required type="tel" class="form-control" id="phoneNumber" placeholder="Phone Number" name="phoneNumber" v-mask="'(###) ###-####'">
          <label for="phoneNumber">Phone Number</label>
        </div>
      </div>
      <div class="col-12 mb-3">
        <div class="form-floating">
          <input required type="text" class="form-control" id="streetAddress" placeholder="Street Address" name="streetAddress">
          <label for="streetAddress">Street Address</label>
        </div>
      </div>
      <div class="col-sm-6 mb-3">
        <div class="form-floating">
          <input required type="text" class="form-control" id="city" placeholder="City" name="city">
          <label for="city">City</label>
        </div>
      </div>
      <div class="col-sm-3 col-6 mb-3">
        <div class="form-floating">
          <input required type="text" class="form-control" id="state" placeholder="State" name="state">
          <label for="state">State</label>
        </div>
      </div>
      <div class="col-sm-3 col-6 mb-3">
        <div class="form-floating">
          <input required type="text" class="form-control" id="zip" placeholder="Zip Code" name="zip" v-mask="'#####'">
          <label for="zip">Zip Code</label>
        </div>
      </div>

        </div>
      </div>
  </div>



    <div class="card mb-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5>First Dog</h5>
        <span>$45</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="firstDogName" placeholder="Dog's Name" name="firstDogName">
              <label for="firstDogName">Dog's Name</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="firstDogBreed" placeholder="Breed" name="firstDogBreed">
              <label for="firstDogBreed">Breed</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="firstDogAge" placeholder="Age" name="firstDogAge">
              <label for="firstDogAge">Age</label>
            </div>
          </div>
          <div class="col-sm-8 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="firstDogDescription" placeholder="Color / Description" name="firstDogDescription">
              <label for="firstDogDescription">Color / Description</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="firstDogWeight" placeholder="Weight" name="firstDogWeight">
              <label for="firstDogWeight">Weight</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="firstDogSex" name="firstDogSex" aria-label="Sex">
                <option value="" selected>Select One...</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <label for="firstDogSex">Sex</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="firstDogSpayedOrNeutered" name="firstDogSpayedOrNeutered" aria-label="Spayed or Neutered">
                <option value="" selected>Select One...</option>
                <option value="Not Spayed or Neutered">Not Spayed or Neutered</option>
                <option value="Spayed or Neutered">Spayed or Neutered</option>
              </select>
              <label for="firstDogSpayedOrNeutered">Spayed or Neutered</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="firstDogMicrochipped" name="firstDogMicrochipped" aria-label="Microchipped">
                <option value="" selected>Select One...</option>
                <option value="Not Microchipped">Not Microchipped</option>
                <option value="Microchipped">Microchipped</option>
              </select>
              <label for="firstDogMicrochipped">Microchipped</label>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="firstDogVet" placeholder="Name & Address of Vet or Animal Clinc" name="firstDogVet">
              <label for="firstDogVet">Name & Address of Vet or Animal Clinc</label>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="form-floating">
              <input required type="date" class="form-control" id="firstDogRabiesDate" placeholder="Date of Last Rabies / Titers" name="firstDogRabiesDate" :max="today" :min="twentyYearsAgo" data-bouncer-message="You cannot enter a date in the future or more than 20 years ago">
              <label for="firstDogRabiesDate">Date of Last Rabies / Titers</label>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="form-floating">
              <input required type="file" style="padding-top:30px;padding-left:20px;" class="form-control form-control-sm" id="firstDogRabiesDocumentation" name="firstDogRabiesDocumentation" accept="image/png, image/gif, image/jpeg, image/jpg, image/bmp, application/pdf, application/msword">
              <label for="firstDogRabiesDocumentation">Rabies / Titers Documentation</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5>Second Dog</h5>
        <div class="form-check">
          <input class="form-check-input novalidate" type="checkbox" v-model="secondDog" :disabled="thirdDog" id="secondDog" />
          <label class="form-check-label novalidate" for="secondDog">Add a second dog (+$30)</label>
        </div>
      </div>
      <div class="card-body" v-if="secondDog">
        <div class="row">
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="secondDogName" placeholder="Dog's Name" name="secondDogName">
              <label for="secondDogName">Dog's Name</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="secondDogBreed" placeholder="Breed" name="secondDogBreed">
              <label for="secondDogBreed">Breed</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="secondDogAge" placeholder="Age" name="secondDogAge">
              <label for="secondDogAge">Age</label>
            </div>
          </div>
          <div class="col-sm-8 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="secondDogDescription" placeholder="Color / Description" name="secondDogDescription">
              <label for="secondDogDescription">Color / Description</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="secondDogWeight" placeholder="Weight" name="secondDogWeight">
              <label for="secondDogWeight">Weight</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="secondDogSex" name="secondDogSex" aria-label="Sex">
                <option value="" selected>Select One...</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <label for="secondDogSex">Sex</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="secondDogSpayedOrNeutered" name="secondDogSpayedOrNeutered" aria-label="Spayed or Neutered">
                <option value="" selected>Select One...</option>
                <option value="Not Spayed or Neutered">Not Spayed or Neutered</option>
                <option value="Spayed or Neutered">Spayed or Neutered</option>
              </select>
              <label for="secondDogSpayedOrNeutered">Spayed or Neutered</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="secondDogMicrochipped" name="secondDogMicrochipped" aria-label="Microchipped">
                <option value="" selected>Select One...</option>
                <option value="Not Microchipped">Not Microchipped</option>
                <option value="Microchipped">Microchipped</option>
              </select>
              <label for="secondDogMicrochipped">Microchipped</label>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="secondDogVet" placeholder="Name & Address of Vet or Animal Clinc" name="secondDogVet">
              <label for="secondDogVet">Name & Address of Vet or Animal Clinc</label>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="form-floating">
              <input required type="date" class="form-control" id="secondDogRabiesDate" placeholder="Date of Last Rabies / Titers" name="secondDogRabiesDate" :max="today" :min="twentyYearsAgo" data-bouncer-message="You cannot enter a date in the future or more than 20 years ago">
              <label for="secondDogRabiesDate">Date of Last Rabies / Titers</label>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="form-floating">
              <input required type="file" style="padding-top:30px;padding-left:20px;" class="form-control form-control-sm" id="secondDogRabiesDocumentation" name="secondDogRabiesDocumentation" accept="image/png, image/gif, image/jpeg, image/jpg, image/bmp, application/pdf, application/msword">
              <label for="secondDogRabiesDocumentation">Rabies / Titers Documentation</label>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5>Third Dog</h5>
        <div class="form-check">
          <input class="form-check-input novalidate" type="checkbox" v-model="thirdDog" :disabled="!secondDog" id="thirdDog" />
          <label class="form-check-label novalidate" for="thirdDog">Add a third dog (+$30)</label>
        </div>
      </div>
      <div class="card-body" v-if="thirdDog">
        <div class="row">
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="thirdDogName" placeholder="Dog's Name" name="thirdDogName">
              <label for="thirdDogName">Dog's Name</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="thirdDogBreed" placeholder="Breed" name="thirdDogBreed">
              <label for="thirdDogBreed">Breed</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="thirdDogAge" placeholder="Age" name="thirdDogAge">
              <label for="thirdDogAge">Age</label>
            </div>
          </div>
          <div class="col-sm-8 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="thirdDogDescription" placeholder="Color / Description" name="thirdDogDescription">
              <label for="thirdDogDescription">Color / Description</label>
            </div>
          </div>
          <div class="col-sm-4 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="thirdDogWeight" placeholder="Weight" name="thirdDogWeight">
              <label for="thirdDogWeight">Weight</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="thirdDogSex" name="thirdDogSex" aria-label="Sex">
                <option value="" selected>Select One...</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <label for="thirdDogSex">Sex</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="thirdDogSpayedOrNeutered" name="thirdDogSpayedOrNeutered" aria-label="Spayed or Neutered">
                <option value="" selected>Select One...</option>
                <option value="Not Spayed or Neutered">Not Spayed or Neutered</option>
                <option value="Spayed or Neutered">Spayed or Neutered</option>
              </select>
              <label for="thirdDogSpayedOrNeutered">Spayed or Neutered</label>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="form-floating">
              <select required class="form-select" id="thirdDogMicrochipped" name="thirdDogMicrochipped" aria-label="Microchipped">
                <option value="" selected>Select One...</option>
                <option value="Not Microchipped">Not Microchipped</option>
                <option value="Microchipped">Microchipped</option>
              </select>
              <label for="thirdDogMicrochipped">Microchipped</label>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="form-floating">
              <input required type="text" class="form-control" id="thirdDogVet" placeholder="Name & Address of Vet or Animal Clinc" name="thirdDogVet">
              <label for="thirdDogVet">Name & Address of Vet or Animal Clinc</label>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="form-floating">
              <input required type="date" class="form-control" id="thirdDogRabiesDate" placeholder="Date of Last Rabies / Titers" name="thirdDogRabiesDate" :max="today" :min="twentyYearsAgo" data-bouncer-message="You cannot enter a date in the future or more than 20 years ago">
              <label for="thirdDogRabiesDate">Date of Last Rabies / Titers</label>
            </div>
          </div>
          <div class="col-sm-6 mb-3">
            <div class="form-floating">
              <input required type="file" style="padding-top:30px;padding-left:20px;" class="form-control form-control-sm" id="thirdDogRabiesDocumentation" name="thirdDogRabiesDocumentation" accept="image/png, image/gif, image/jpeg, image/jpg, image/bmp, application/pdf, application/msword">
              <label for="thirdDogRabiesDocumentation">Rabies / Titers Documentation</label>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <div class="card legal-language mb-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5>Rules and Regulations</h5>
      </div>
      <div class="card-body">
        
          <ul>
            <li>Hours of operation: 5:00 a.m. - 10:00 p.m. Daily (Normal Compton Hill Reservoir Park hours)</li>
            <li>Owner(s) are legally responsible for their dogs and any injuries or damages caused by them.</li>
            <li>No vicious dogs allowed. All fights between dogs must be attended to by dog's owners / handlers.</li>
            <li>Owners / handlers must be inside the fenced area of the Dog Park at all times while their dogs are in the off leash area. Dogs are not to be left in the WTDP unattended.</li>
            <li>Leash laws apply outside the fenced area of the WTDP.</li>
            <li>Children under 14 are not allowed in the fenced area of the WTDP unless accompanied by a responsible, legal adult.</li>
            <li>Dogs must have proof of rabies vaccination or approved titers from a licensed veterinarian.</li>
            <li>Gates must remain closed except when entering or exiting the off leash or catch pin areas.</li>
            <li>No choke, pinch or spike collars allowed in the park area. Dogs must wear leather or smooth cloth collars and display WTDP identification tag when visiting the WTDP off-leash areas.</li>        
            <li>WTDP is for members only; do not give the gate code to a non-member.</li>
            <li>Limit 3 dogs per owner / handler at any time in the WTDP.</li>
            <li>Owners / handlers are responsible for picking up their dogs's waste. Place all waste and trash in designated receptacles. This rule is for the off leash areas as well as the on leash areas too (i.e., the rest of Compton Hill Reservoir Park).</li>
          </ul>

          <p><strong>Failure to comply with any of the rules and regulations for the WTDP will result in immediate termination of all use privileges related to the WTDP (without any refund of annual fees paid).</strong></p>

          <h5>NOT ALLOWED in the Dog Park</h5>
          <ul>
            <li>Vicious dogs</li>
            <li>Dogs in heat</li>
            <li>Animals other than dogs</li>
            <li>Puppies under 3 months of age</li>
            <li>Glass of any kind</li>
            <li>Food other than dog treats</li>
            <li>Smoking</li>
            <li>Bikes, roller blades, scooters, or other motorized devices</li>
            <li>Dogs that are injured, sick, or in poor health</li>
          </ul>

          <p>All parties are encouraged to take responsibility for the WTDP. Politely notify patrons if they are breaking the rules and regulations for the WTDP. Notify the WTDP if a patron is habitually disregarding the rules and regulations. Should you have any complaints, concerns, or questions regarding the rules and regulations, please e-mail the WTDP at <a href="mailto:watertowerdogparkstl@gmail.com">watertowerdogparkstl@gmail.com</a> or mail your written request to Water Tower Dog Park P.O. Box 630 St. Louis MO 63163.</p>

          <div class="form-check">
            <input required type="checkbox" class="form-check-input" id="agreeToRules" name="agreeToRules" />
            <label class="form-check-label" for="agreeToRules"><strong>I have read and agree to comply with the above stated rules and regulations.</strong></label>
          </div>
        
      </div>
    </div>
      

       
    <div class="card legal-language mb-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5>Release and Waiver of Liability</h5>
      </div>
      <div class="card-body">
        <p>I hereby acknowledge that I have applied for permission to use, together with dog(s) owned by me and/or under my supervision, the
        Water Tower Dog Park located in Compton Hill Reservoir Park (hereafter referred to as the “Dog Park”). I understand that the act of
        unleashing my dog(s) and being physically present inside the Dog Park necessarily involves risks of injury to me, other people, my
        dog(s) and other dogs, which risks are entirely my responsibility. In consideration of my entry upon and use of the Dog Park, I
        expressly and voluntarily assume these risks and all additional risks associated with entry upon and use of the Dog Park, including
        those identified below. I further understand that dog(s), irrespective of their training and usual past behavior or characteristics, may act
        or react unpredictably at times based upon instinct or circumstances, and I agree to assume the risk of injury to me, all individuals
        accompanying me in the Dog Park, and my dog(s). I understand that this risk may result from fierce, aggressive, vicious and
        dangerous dogs, which may be present in the Dog Park. I further understand and assume the risk that not all dog(s) present in the Dog
        Park may have received the Rabies vaccine as required by law, and that not all dogs using the Dog Park may have been vaccinated for
        Distemper or Parvo, all of which could result in injury to me, individuals accompanying me and my dog(s). Additional risks include,
        but are not limited to: dog fights, dog bites and injuries to humans and other dog(s); dog theft or unlawful capture; dog escape over or
        under fences; plants and/or water sources in the Dog Park that may be poisonous to dogs; vegetation in the Dog Park containing burrs
        or seeds that could become tangled in a dog's coat or lodge in a dog's feet, ear, nose, throat or eyes; mosquitoes, ticks, chiggers, fleas
        or other insects and animals that may be present in the Dog Park, all of which might injure or infect my dog(s). I understand and
        expressly assume all additional risks.</p>

        <p>It is my understanding that no agent or employee of The City of St. Louis or Water Tower Dog Park, Inc. will supervise the Dog Park
        at any time. I further understand that The City of St. Louis and Water Tower Dog Park do not assume any liability for loss or damage
        for any injury sustained by any human or dog while using the Dog Park. I, therefore, expressly assume all risks associated with entry
        upon and use of the Dog Park, as well as fixtures and equipment located therein, in an unsupervised manner.<p>

        <p>By agreeing to this release of liability and entering upon and using the Dog Park, I, for myself and anyone claiming by or through me,
        hereby fully and forever waive, release and discharge The City of St. Louis, Water Tower Dog Park, Water Tower & Park
        Preservations Society, Inc. and Compton Hill Reservoir Square Residents Association and each of their respective affiliates, officials,
        officers, directors, members, employees, agents, licensees and invitees (the “Released Parties”) from, and agree to indemnify, save
        and hold harmless the Released Parties from and against, any and all claims, demands, damages, losses, liabilities, costs or causes of
        action, present or future, whether the same be known or unknown, anticipated or unanticipated, resulting from or arising out of the
        entry upon and use (or intended use) of the Dog Park by me and dog(s) owned by me and/or under my supervision. I fully and forever
        release and discharge the Released Parties from any and all negligent acts and omissions in the same, and intend to be legally bound
        by this release and waiver of liability.</p>

        <p>I have carefully read this release and waiver of liability and understand and fully agree with its contents. I agree that this release and
        waiver of liability is intended to be as broad and exclusive as permitted by the laws of the State of Missouri, and that if any portion
        thereof is held invalid, I agree that the balance shall continue in full force and effect. I also acknowledge receipt of a copy of the rules
        and regulations for use of the Dog Park.</p>

        <p><strong>This is a release and waiver of liability. Do not check the box below if you do not understand or do not agree with its terms.</strong></p>

        <div class="form-check">
          <input required type="checkbox" class="form-check-input" id="agreeToTerms" name="agreeToTerms" />
          <label class="form-check-label" for="agreeToTerms"><strong>I understand and agree to these terms.</strong></label>
        </div>

      </div>
    </div>


    <div class="card legal-language mb-3">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h5>Payment</h5>
      </div>
      <div class="card-body">
      
      <p>Upon approval of this application and payment of the annual fees listed below, the individual and the dogs identified in this application may use the WTDP in accordance with all applicable rules and regulations through the end of the calendar year. <i><u>Annual fees paid to the WTDP are nonrefundable and are not exempt from income tax.</u></i></p>
      

      <div class="stripe-payment-container">
        <fieldset>
          <legend class="stripe-header-card" style="display: block;">Enter payment details</legend>
          <stripe-element-card
            class="mb-3"
            ref="paymentRef"
            mode="payment"        
            :pk="publishableKey"
            :element-style="stripeStyle"
            :confirm-params="confirmParams"
            :elements-options="elementsOptions"
            @token="tokenCreated"
          />
          <button :class="['btn', 'btn-primary', 'mb-3', 'ld-ext-right', {'running': isSubmitting}]" @click.prevent="submit">Submit Application and Pay ${{amount}} for {{numberOfDogs}}<div class="ld ld-ring ld-spin"></div></button>
        </fieldset>
      </div>

      </div>
    </div>
    </form>
    <div v-if="submissionError">
      <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">Unable to submit application</h4>
        <p>Error: {{errorMessage}}</p>
        <p>Please review your submission and try again. If you're unable to submit an application please send an email to <a href="watertowerdogparkstl@gmail.com">watertowerdogparkstl@gmail.com</a>.</p>
      </div>
    </div>
    <div v-if="applicationSubmitted">
      <div class="alert alert-success" role="alert">
        <h4 class="alert-heading">Application Submitted!</h4>
        <p>Thank you. A copy of your application should be in your email inbox shortly. Please allow up to 14 days to process your application. You will receive an email confirmation once your application has been processed and approved.</p>
      </div>
    </div>
  </div>
</template>

<script>

import { StripeElementCard  } from '@vue-stripe/vue-stripe';
import axios from 'axios';
import Bouncer from 'formbouncerjs';
import {mask} from 'vue-the-mask';
import * as filestack from 'filestack-js';
const client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);

export default {
  directives: {mask},
  components: {
    StripeElementCard ,
  },
  name: 'Form',
  props: {
    msg: String
  },
  data () {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      token: null,
      secondDog: false,
      thirdDog: false,
      uniqueId: 0,
      applicationSubmitted: false,
      submissionError: false,
      isSubmitting: false,
      errorMessage: 'An unexpected error occurred.',
      stripeStyle: {
        base: {
          color: "#32325D",
          fontWeight: 500,
          fontFamily: "Inter UI, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#CFD7DF"
          }
        },
        invalid: {
          color: "#E25950"
        }
      },
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: 'http://localhost:8080/success', // success url
      },
    };
  },
  mounted () {
    this.uniqueId = (new Date()).getTime();
  },
  computed: {
    numberOfDogs(){
      return this.thirdDog ? 'Three Dogs' : (this.secondDog ? 'Two Dogs' : 'One Dog');
    },
    amount(){
      return this.thirdDog ? 105 : (this.secondDog ? 75 : 45);
    },
    stripeAmount(){
      return this.amount * 100;
    },
    today(){
      return new Date().toLocaleDateString('en-ca')
    },
    twentyYearsAgo(){
      let currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 20)
      return currentDate.toLocaleDateString('en-ca')
    }
  },
  methods: {
    async tokenCreated (token) {
      const self = this;

      var form = document.querySelector('form');
      var data = new FormData(form);
      data.append('amount', this.stripeAmount);
      data.append('token', token.id);
      data.append('idempotency_key', this.uniqueId);

      let [firstDocumentation, firstDocumentationName] = await client.upload(document.getElementById('firstDogRabiesDocumentation').files[0])
      .then(res => {
        return [res.url, res.filename];
      });

      let secondDocumetation = '';
      let secondDocumetationName = '';
      if (document.getElementById('secondDogRabiesDocumentation')) {
        [secondDocumetation, secondDocumetationName] = await client.upload(document.getElementById('secondDogRabiesDocumentation').files[0])
        .then(res => {
          return [res.url, res.filename];
        });
      }

      let thirdDocumentation = '';
      let thirdDocumentationName = '';
      if (document.getElementById('thirdDogRabiesDocumentation')) {
        [thirdDocumentation, thirdDocumentationName] = await client.upload(document.getElementById('thirdDogRabiesDocumentation').files[0])
        .then(res => {
          return [res.url, res.filename];
        });
      }

      data.delete('firstDogRabiesDocumentation');
      data.delete('secondDogRabiesDocumentation');
      data.delete('thirdDogRabiesDocumentation');
      data.append('firstDogRabiesDocumentation', firstDocumentation);
      data.append('firstDogRabiesDocumentationName', firstDocumentationName);
      data.append('secondDogRabiesDocumentation', secondDocumetation);
      data.append('secondDogRabiesDocumentationName', secondDocumetationName);
      data.append('thirdDogRabiesDocumentation', thirdDocumentation);
      data.append('thirdDogRabiesDocumentationName', thirdDocumentationName);

      await axios.post('/.netlify/functions/stripe-charge', data, { headers: {'content-type': 'multipart/form-data' }})
      .then(function (response) {
        if (response.status == 200){
          self.submissionError = false;
          self.applicationSubmitted = true;
          self.uniqueId = (new Date()).getTime();
          form.classList.remove('was-validated');
          form.reset();
          self.$refs.paymentRef.clear();
        }
        else {
          self.submissionError = true;
        }
      }).catch(function (error) {
        self.errorMessage = error.response.data.errorMessage ? error.response.data.errorMessage : 'An unexpected error occurred.';
        self.uniqueId = (new Date()).getTime();
        self.submissionError = true;
      });
      this.isSubmitting = false;
    },
    submit () {
      var form = document.querySelector('form');
      var bouncer = new Bouncer('form', { errorClass: 'invalid-feedback'});
      var areValid = bouncer.validateAll(form);

      if (areValid.length === 0) {
        this.isSubmitting = true;
        this.$refs.paymentRef.submit();
      }
      else {
        form.classList.add('was-validated')
        document.querySelector('input:invalid').scrollIntoViewIfNeeded();
      }

    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-xxl{
  background-color:#fff;
  padding-bottom:var(--bs-gutter-x, 0.75rem);
}

@media only screen and (min-width: 992px){
  .container-xxl{
    margin-bottom: 400px; 
  }
}

.card.legal-language{
  font-size:14px;
}

.novalidate {
  color: #2c3e50!important;
  border-color:#2c3e50!important;
}

input[type=checkbox].novalidate:checked{
  background-color: #0d6efd!important;
}

</style>